<template>
  <el-container class="reset-password-page">
    <!-- <el-header class="header-box">
    </el-header> -->
    <div class="logo-img"></div>
    <el-main class="main-box">
      <h1 class="title">{{$t('Band to your account')}}</h1>
      <el-form
          :model="form"
          :rules="rules"
          ref="formRef">
        <el-form-item prop="username">
          <el-input
              class="input-box"
              :placeholder="($t('Account'))"
              v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              class="input-box"
              :placeholder="($t('Password'))"
              v-model="form.password"
              type="password"
              show-password></el-input>
        </el-form-item>

        <el-form-item prop="inputCaptcha">
          <div class="captcha-box">
            <el-input
                :placeholder="($t('captcha'))"
                class="captcha-input"
                v-model="form.inputCaptcha"
                auto-complete="off">
            </el-input>
            <img class="captcha-img" :src="captchaSrc" @click="queryCaptcha"/>
          </div>
        </el-form-item>
        <el-form-item type="flex"   justify="space-between">
          <div class="space-flex-container">
            <span class="text1">{{$t(`Don't have an account?`)}}</span>
            <span class="text1 signup-btn" @click="registerFunc">{{$t('Sign up')}}</span>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button
              class="modify-button"
              type="primary"
              @click="submitForm(formRef)">
            {{ $t('Band') }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer class="footer-box">
      <span class="policy-text">{{$t('Terms of use')}}</span>
      <span class="split-line"></span>
      <span class="policy-text">{{$t('Privacy policy')}}</span></el-footer>
  </el-container>
</template>


<script setup>
import {reactive, ref, onBeforeMount} from 'vue';
import loginApi from '../../api/loginApi';
import { useRoute,useRouter } from 'vue-router';
const router = useRouter(); // 使用 useRouter 获取路由实例
const currentRoute = useRoute()
const formRef = ref(null)
const form = reactive({
  username: '',
  password: '',
  newPassword: '',
  confirmNewPassword: '',
  inputCaptcha: ''
})

// const validatePassword = (rule,value,callback) => {
//     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;

//     if(value == ''){
//         callback(new Error('Is required'))
//     }else if (!passwordRegex.test(value)) {
//         callback(new Error('password rule'))
//     } else {
//         callback()
//     }
// };

const validateCaptcha = (rule, value,callback) => {
  console.log('validateCaptcha value = ',value);
  console.log('validateCaptcha captchaText = ',captchaText);
  if(value == ''){
    callback(new Error('Is required'))
  } else {
    callback()
  }
}

const rules = reactive({
  username: [
    { required: true, message: 'Please input username', trigger: 'blur' },
  ],
  password: [
    { required: true, message: 'Please input password', trigger: 'blur' },
  ],

  inputCaptcha: [
    { validator: validateCaptcha,message: '', trigger: 'blur'}
  ]
})

const submitForm = async (formR) => {

  if (!formR) return
  formR.validate(async (valid) => {
    if (valid) {
      const type = currentRoute.query.type; // 使用 currentRoute 获取查询参数
      const thirduuid = currentRoute.query.thirduuid;
      const param = {
        username: form.username,
        password: form.password,
        'captchaId': captchaText.value,
        'captcha': form.inputCaptcha,
        type: type,
        thirduuid
      }
      const res = await loginApi.bandUserUUId(param)
      console.log('modifyPassword res = ',res)
      if(res.code === 0){
        alert('绑定成功')
        QRScan();
      }else{
        queryCaptcha();
        alert(res.data.msg)
        return;
      }
    } else {
      console.log('error submit!')
      return false
    }
  })
}

const captchaSrc = ref('')
const captchaText = ref('')

const queryCaptcha = async () => {
  const res = await loginApi.queryCaptchaMo()
  captchaSrc.value = res.data.img
  captchaText.value = res.data.captchaId
}
const QRScan =async () =>{
  const res = await loginApi.qrScanRedireact()
  console.log(res)
  if (res.data&&res.data.url){
    window.location.href =res.data.url;
  }else {
    alert(res.data)
  }
}

const registerFunc= async () =>{
  // eslint-disable-next-line no-debugger
  router.push('/Register')
}
onBeforeMount(() => {
  console.log('currentRoute = ',currentRoute.query)
  if(currentRoute.query.client_id!=undefined
      &&currentRoute.query.redirect_uri!=undefined){
    localStorage.setItem('client_id',currentRoute.query.client_id)
    localStorage.setItem('redirect_uri',currentRoute.query.redirect_uri)
  }
  queryCaptcha()
})
</script>

<style scoped>
.reset-password-page{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-box{
  flex-shrink: 0;
}
.main-box{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo-img{
  width: 100px;
  height: 100px;
  background-color: #19c37d;
  /* color: white; */
  text-align: center;
  line-height: 50px;
  font-weight: 900;
  margin-top: 32px;
  background: url('../assets/logo.png');
  background-size: 100% 100%;
  /* flex-shrink: 0; */
}

.title {
  font-size: 30px;
  /* margin-bottom: 20px; */
}

.input-box{
  width: 320px;
  height: 52px;
  /* margin-top: 16px; */
}
.captcha-box{
  display: flex;
}

.captcha-input{
  width: 200px;
  /* margin-top: 16px; */
}

.captcha-img{
  width: 120px;
  height: 50px;
  /* margin-top: 16px; */
  display: inline;
  object-fit: cover;
  padding: 1px;
  cursor: pointer;
}

.modify-button{
  width: 322px;
  height: 52px;
  background-color: #19c37d;
  border-color: #19c37d;
  margin-top: 16px;
  line-height: 52px;
  font-weight: 600;
  font-size: 20px;
  color: white;
  border-radius: 2px;
  cursor: pointer;
}
.footer-box{
  height: 50px;
  margin-bottom: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
}
.signup-btn{
  font-weight: 600;
  color: #19c37d;
  cursor: pointer;
}
.policy-text{
  color: #19c37d;
  cursor: pointer;
}

.split-line{
  height: 20px;
  width: 1px;
  display: inline-block;
  background: black;
  margin: 0 10px;
}
</style>